import Vue from 'vue'
import App from './App.vue'
import router from './router.js'


import '@/assets/css/markdown.min.css'

import highlight from 'highlight.js'
import 'highlight.js/styles/atom-one-dark.css'
import "./assets/icons/iconfont.css"
import './assets/css/iconfont.css';
// elementui
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
// 引入百度地图
import BaiduMap from 'vue-baidu-map'
import 'default-passive-events'
import VueAnimateNumber from 'vue-animate-number'
Vue.use(VueAnimateNumber)
import VueMeta from 'vue-meta';

Vue.use(VueMeta);
// 代码高亮
Vue.use(highlight)
Vue.directive('highlight', (el) => {
  let blocks = el.querySelectorAll('pre code')
  blocks.forEach((block) => {
    highlight.highlightBlock(block)
  })
})
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: 'o2cmUubuPWzGp1MMwj5ZCZCLe2CC20oH'
})
Vue.use(ElementUI);
Vue.config.productionTip = false

new Vue({
	router,
  render: h => h(App),
  

  // mounted() {
  //   const userAgent = navigator.userAgent.toLowerCase();
  //    console.log(userAgent);
  //   if (/mobile/i.test(userAgent)) {
  //     // 用户为手机设备，重定向到手机域名
  //     window.location.href = '手机域名';
  //   } else {
  //     // 用户为电脑设备，重定向到电脑域名
  //     window.location.href = '电脑域名';
  //   }
  // },
}).$mount('#app')

