import Router from "vue-router";
import Vue from "vue";
import Home from "./views/Home/index";

Vue.use(Router);

const routes = [
  {
    path: "/",
    name: "home",
    component: Home,

    redirect: "/index",
    children: [
      {
        path: "/index",
        name: "home-page",
        component: () => import("./views/Home/home-page/index.vue"),
      },
      {
        path: "/product",
        name: "product",
        component: () => import("./views/Home/product-introduction/index.vue"),
        meta: {
          navbarColor:'#EDF4FE' // 设置导航栏背景色
        }
      },
      {
        path: "/scheme",
        name: "scheme",
        component: () => import("./views/Home/scheme/index.vue"),
      },
      {
        path: "/contact",
        name: "contact",
        component: () => import("./views/Home/contact/index.vue"),
      },
      {
        path: "/aboutus",
        name: "aboutus",
        component: () => import("./views/Home/aboutus/index.vue"),
      },
      {
        path: "/fillinformation",
        name: "fillinformation",
        component: () => import("./views/Home/home-page/fillinformation.vue"),
      },
      // 预算
      {
        path: "/budget",
        name: "budget",
        component: () => import("./views/Home/product-introduction/budget.vue"),
        // meta: {
        //   navbarColor:'#eef3fe' // 设置导航栏背景色
        // }
      },
      {
        path: "/business",
        name: "business",
        component: () =>
          import("./views/Home/product-introduction/business.vue"),
          // meta: {
          //   navbarColor:'#eef3fe' // 设置导航栏背景色为红色
          // }
        
      },
      {
        path: "/dairy",
        name: "dairy",
        component: () =>
          import("./views/Home/product-introduction/dairy.vue"),
      },
      {
        path: "/calf",
        name: "calf",
        component: () =>
          import("./views/Home/product-introduction/calf.vue"),
      },
      {
        path: "/hahathree",
        name: "hahathree",
        component: () => import("./views/Home/scheme/hahathree.vue"),
      },
      {
        path: "/hahafour",
        name: "hahafour",
        component: () => import("./views/Home/scheme/hahafour.vue"),
      },
    ],
  },
];


const router = new Router({
  //这个路由实例化要放在routes下面
  mode: "hash",
  routes,
});





export default router;
