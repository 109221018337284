<template>
  <div class="header-navbox" :class="{ fixed: isFixed }">
    <div class="header" :style="{ background: navbarColor }">
      <ul class="logo_box">
        <li>
          <img
            src="https://qncdn.uni-sheep.com/s/%E8%87%AA%E7%89%A7%E5%A4%A9%E6%88%904-4logo2.png"
            alt=""
            class="sheep_img"
          />
        </li>
        <li>
          <img
            src="https://qncdn.uni-sheep.com/s/%E5%92%8C%E7%89%A7%E5%85%B4%E9%82%A6logo%201%20%281%29%281%29.png"
            alt=""
            class="unidairy_img"
          />
        </li>
      </ul>

      <ul class="tab_box">
        <li>
          <el-menu
            :style="{ background: navbarColor }"
            router
            :default-active="activeIndex"
            class="el-menu-demo"
            mode="horizontal"
          >
            <el-menu-item index="/index">首页</el-menu-item>
            <el-submenu index="1">
              <template slot="title">产品介绍</template>
              <div class="subgrade">
                <div>
                  <div class="menu-item-wrapper">
                    <el-menu-item index="/product">
                      <div class="menu-item-container">
                        <img
                          src="https://qncdn.uni-sheep.com/s/Group%20659.png"
                          alt=""
                          class="icon-image"
                          style="
                            margin-left: 0.3125rem;
                            width: 32px;
                            height: 31px;
                          "
                        />
                        <span style="margin-left: 0.5rem"
                          >UniSheep羊只生产管理平台</span
                        >
                      </div>
                    </el-menu-item>
                  </div>
                  <div class="menu-item-wrapper" style="margin-top: 1.375rem">
                    <el-menu-item index="/business">
                      <div class="menu-item-container">
                        <img
                          src="https://qncdn.uni-sheep.com/s/Group%20664.png"
                          alt=""
                          class="icon-image"
                          style="
                            margin-left: 0.3125rem;
                            width: 32px;
                            height: 31px;
                          "
                        />
                        <span style="margin-left: 0.5rem">业财一体系统</span>
                      </div>
                    </el-menu-item>
                  </div>
                  <div class="menu-item-wrapper" style="margin-top: 1.375rem">
                    <el-menu-item index="/budget">
                      <div class="menu-item-container">
                        <img
                          src="https://qncdn.uni-sheep.com/s/Group%20669.png"
                          alt=""
                          class="icon-image"
                          style="
                            margin-left: 0.3125rem;
                            width: 32px;
                            height: 31px;
                          "
                        />
                        <span style="margin-left: 0.5rem">预算系统</span>
                      </div>
                    </el-menu-item>
                  </div>
                </div>
                <div>
                  <div class="menu-item-wrapper">
                    <el-menu-item index="/calf">
                      <div class="menu-item-container">
                        <img
                          src="https://qncdn.uni-sheep.com/s/Group%20665.png"
                          alt=""
                          class="icon-image"
                          style="
                            margin-left: 0.3125rem;
                            width: 32px;
                            height: 31px;
                          "
                        />
                        <span style="margin-left: 0.5rem"
                          >犊牛智能精准饲喂系统</span
                        >
                      </div>
                    </el-menu-item>
                  </div>

                  <!-- <el-menu-item index="">IOT</el-menu-item>
                  <el-menu-item index="">TMR</el-menu-item> -->
                  <!-- <div class="menu-item-wrapper" style="margin-top:1.375rem;">
                    <el-menu-item index="/dairy">
                      <div class="menu-item-container">
                        <img
                        src="https://qncdn.uni-sheep.com/s/Group%20660%281%29.png"
                          alt=""
                          class="icon-image"
                          style="margin-left:0.3125rem ;"
                        />
                        <span style="margin-left:0.5rem ;">UniDairy牛只生产管理平台</span>
                      </div>
                    </el-menu-item>
                  </div> -->
                </div>
              </div>
            </el-submenu>
            <!-- <el-submenu index="2">
              <template slot="title">解决方案</template>
              <el-menu-item index="/scheme">选项1</el-menu-item>
              <el-menu-item index="/hahathree">选项2</el-menu-item>
              <el-menu-item index="/hahafour">选项3</el-menu-item>
            </el-submenu> -->
            <el-menu-item index="/contact">咨询服务</el-menu-item>
            <el-menu-item index="/aboutus">关于我们</el-menu-item>
          </el-menu>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  props: [],
  data() {
    return {
      activeIndex: "1",
      navbarColor: "", // 初始化导航栏背景色
      isFixed: false, // 是否固定导航栏
    };
  },
  methods: {
    updateNavbarColor() {
      // 根据当前的路由来设置导航栏背景色
      this.navbarColor = this.$route.meta.navbarColor || "";
    },
    handleScroll() {
      const navbar = document.querySelector(".header-navbox");
      const navbarPosition = navbar.offsetTop;
      const scrollPosition =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;

      if (scrollPosition >= navbarPosition) {
        this.isFixed = true;
      } else {
        this.isFixed = false;
      }
    },
  },

  mounted() {
    this.updateNavbarColor(); // 在组件挂载后更新导航栏背景色
    window.addEventListener("scroll", this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.handleScroll, true);
  },
  watch: {
    $route() {
      this.updateNavbarColor(); // 监听路由变化，更新导航栏背景色
      // 跳转到其他页面页面重新加载下
      window.location.reload();
    },
  },
};
</script>
<style scoped>
@keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-20px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}

.subgrade {
  /* display: flex; */
  width: 42rem;
  height: 13.5rem;
  /* border: 1px solid #000; */
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  /* justify-content: center; */
  /* border: 1px solid #000; */
}
.header-navbox {
  width: 100%;
  height: 4.5625rem;

  /* border: 1px solid #000; */
  background: white;
}
.fixed {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 9999;
  animation: fadeInDown 1s 0.2s ease both;
}
.header {
  width: 100%;
  height: 4.5625rem;
  /* border: 1px solid #000; */
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* background: #000; */

  margin: 0 auto;
}
.logo_box {
  width: 15%;
  display: flex;
  height: 4.5rem;
  align-items: center;
  /* border: 1px solid #000; */
}
.sheep_img {
  width: 8.6875rem;
  height: 8.6875rem;
  margin-left: 1.68rem;
}
.unidairy_img {
  width: 6.3125rem;
  height: 2.42rem;

  margin-left: 0.68rem;
}
.tab_box {
  /* width: 90%; */
  display: flex;
  justify-content: flex-end;
  padding-right: 5rem;

  /* border: 1px solid #000; */
}

.tab_box li {
  font-size: 1rem;
  margin-left: 2rem;
}
.el-menu {
  background-color: transparent !important;
}
.menu-item-wrapper:hover .menu-item-container {
  background-color: #dff1fe; /* 设置鼠标移入时的背景色 */
  border-radius: 0.3125rem;
}

.menu-item-container {
  width: 18.9rem;
  height: 3.5rem;
  margin-bottom: 0.5rem;
  margin-top: 1.3125rem;
  color: #333333;
  display: flex;
  align-items: center;
}

::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
  font-size: 1.2rem !important;
}
/* //设置一级菜单和二级菜单的背景颜色 */
::v-deep.el-menu-item {
  position: relative;
  font-size: 1.2rem !important;

  /* color: black !important; */
}

/* //设置选鼠标指针浮动在一级菜单的设置 */
::v-deep.el-menu-item:hover {
  color: #333333 !important;
  /* font-weight: 800; */
}
.el-menu--horizontal > .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal > .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  background: none;
}
.el-menu-item.is-active {
  color: #333333 !important;
  /* font-weight: 800 !important; */
}

::v-deep.el-submenu {
  position: relative;
}

::v-deep.el-menu--horizontal > .el-menu-item:hover::after,
.el-submenu:hover::after {
  position: absolute;
  bottom: -0.3125rem;
  left: 75%;
  transform: translate(-75%, -50%);
  content: "";
  width: 95%;
  height: 60%;
  border-radius: 0.25rem;
  background: rgba(219, 232, 254) !important;
  border-radius: 1.25rem;
  mix-blend-mode: multiply; /* 使用multiply混合模式，使文字透过背景色显示 */
}
.el-submenu.is-active:after {
  transform: translate(-80%, -50%);
}
.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: none;
}
.el-menu--horizontal > .el-menu-item {
  border-bottom: none;
  text-decoration: none;
}

::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
  border-bottom: none;
  font-size: 1rem;
}
::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  color: #333333 !important;
  background-color: transparent !important;
  /* font-weight: 800 !important; */
}
::v-deep.el-menu--horizontal > .el-menu-item {
  color: #333333 !important;
}
::v-deep.el-menu--horizontal > .el-submenu .el-submenu__title {
  color: #333333 !important;
}
::v-deep.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: none;
  color: #333333 !important;
  /* font-weight: 800 !important; */
}
.el-menu.el-menu--horizontal {
  border-bottom: none;
}

/* element样式 */
.el-dropdown-link {
  cursor: pointer;
  color: #409eff;
}
.el-icon-arrow-down {
  font-size: 0.75rem;
}
</style>
<style>
.el-menu--horizontal .el-menu--popup {
  position: relative;
  right: 1.875rem;
}
</style>
