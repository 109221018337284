<template>
  <div>
    <router-view></router-view>
  </div>
</template>

<script>

// import DevicePixelRatio from "./utils/devicePixelRatio"; //适配屏幕分辨率与屏幕的缩放适配


export default {
  props: [],
  data() {
    return {};
  },
  methods: {
   
  },
  computed: {},
  watch: {},
  beforeCreate() {},
  created() {
    // 静止浏览器滚动
    // window.addEventListener(
    //   "mousewheel",
    //   function (event) {
    //     if (event.ctrlKey === true || event.metaKey) {
    //       event.preventDefault();
    //     }
    //   },
    //   { passive: false }
    // );

    // //firefox
    // window.addEventListener(
    //   "DOMMouseScroll",
    //   function (event) {
    //     if (event.ctrlKey === true || event.metaKey) {
    //       event.preventDefault();
    //     }
    //   },
    //   { passive: false }
    // );
      // 刷新页面不管怎么缩放，125%还是150%，页面都不会去缩放了，就不会出现错乱的问题了
      // new DevicePixelRatio().init();
  },
  beforeMounte() {},
  mounted() {},
  beforeUpdate() {},
  updated() {},
  beforeDistroy() {},
  distroyed() {},
};
</script>
<style>
body {
  font-family: "San Francisco UI";
  /* 替换 "Your Font Name" 为你想要使用的字体名称，sans-serif 是默认的系统无衬线字体 */
}

</style>
