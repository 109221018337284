<template>
  <div class="home-page">
    <my-header v-if="header_show"> </my-header>
    <el-backtop target=".home-page" :bottom="100">
      <!-- <div
      style="{
        height: 100%;
        width: 100%;
        background-color: #f2f5f6;
        box-shadow: 0 0 6px rgba(0,0,0, .12);
        text-align: center;
        line-height: 40px;
        color: #1989fa;
        border-radius:5px;
      }"
    >
      UP
    </div> -->
    </el-backtop>
    <transition name="fade" mode="out-in">
      <router-view v-on:public_header="public_header"></router-view>
    </transition>
  </div>
</template>


<script>

import MyHeader from "../../layout/header.vue";

export default {
  // name: "nav-home",
  components: {
    MyHeader,
  },
  data() {
    return { header_show: true };
  },
  methods: {
    //是否显示头部
    public_header: function (bool) {
      this.header_show = bool;
    },
  },
  created() {},
  watch: {
    $route() {
      this.header_show = true;
    },
  },
};
</script>
<style>
.home-page {
  overflow: auto !important;
  height: 100vh;
  overflow-x: hidden;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
